<template lang="html">
  <div class="images-carousel" v-if="initialData.images">
    <VueAgile :options="myOptions">
      <div
        class="images-carousel__slide"
        v-for="(item, i) in initialData.images"
        :key="i"
      >
        <img
          v-if="item.image"
          :src="item.image.sizes['medium_large']"
          :alt="item.image.alt"
        />
      </div>
    </VueAgile>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';

export default {
  name: 'ImagesCarousel',
  components: {
    VueAgile,
  },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
        wrapAround: true,
        transition: 400,
      },

      myOptions: {
        speed: 2000,
        fade: true,
        autoplay: false,
        navButtons: false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.images-carousel {
  :deep(.agile) {
    .agile__dot {
      button {
        width: 20px;
        height: 5px;
        margin: 5px 5px 0;
        background-color: $grey;
        transition: background-color 0.3s;
        border: none;
        outline: none;
      }

      &--current {
        button {
          background-color: $cream;
        }
      }
    }
  }
}
</style>
